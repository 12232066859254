import { Link, Text } from '@sobat-bangun/ui/atoms';
import Image from 'next/image';
import { BiEnvelope } from 'react-icons/bi';
import { GrFacebookOption, GrInstagram, GrYoutube } from 'react-icons/gr';

/* Assets */
// import LogoAdidaya from './assets/adidaya.svg';
// import LogoAgraSuryaEnergy from './assets/agra.png';
import LogoAsco from './assets/asco.svg';
import LogoBNI from './assets/bni.svg';
import LogoBRI from './assets/bri.svg';
import LogoBTN from './assets/btn.svg';
// import LogoInecoSolar from './assets/ineco.png';
import LogoJCB from './assets/jcb.svg';
import LogoSIG from './assets/logo-sig.svg';
import Logo from './assets/logo.svg';
import LogoMandiri from './assets/mandiri.svg';
import LogoMasterCard from './assets/mastercard.svg';
import LogoPermataBank from './assets/permata.svg';
import LogoVisa from './assets/visa.svg';
import LogoMark from './assets/mark-of-trust.png';

import { TColSectionFooter, TFooterProps, TSocmedItem } from './types';

export const Footer = ({ serviceMenu, aboutMenu }: TFooterProps) => {
  return (
    <footer className="bg-[#012846] w-full lg:pt-28 lg:pb-12 lg:px-16 px-[24px] py-[40px] overflow-x-hidden text-white">
      <div className="grid grid-cols-12 gap-8 lg:gap-4 mb-8 lg:mb-[64px]">
        <div className="col-span-12 lg:col-span-8">
          <div className="flex flex-col gap-y-5">
            <Image src={Logo} alt="logo" height={48} width={170} loading="lazy" />
            <p className="text-white text-base tracking-wide leading-snug w-[700px] max-w-full">
              SobatBangun adalah platform digital dari SIG yang bergerak dengan misi mengembangkan proses pembangunan dan renovasi rumah secara lebih baik serta
              berkelanjutan.
            </p>
            <div className="flex items-center gap-[14px]">
              <BiEnvelope fontSize={20} />{' '}
              <a href="mailto:tanya.sobatbangun@sig.id" className="underline">
                tanya.sobatbangun@sig.id
              </a>
            </div>
            <div className="hidden md:block">
              <p className="mb-4">Sosial Media :</p>
              <div className="flex gap-x-6">
                <SocmedItem icon={<GrInstagram />} alt="instagram" href="https://www.instagram.com/sobatbangun/" variant="dark" height={15} width={15} />
                <SocmedItem icon={<GrFacebookOption />} alt="facebook" href="https://www.facebook.com/SobatBangun/" variant="light" height={15} width={10} />
                <SocmedItem
                  icon={<GrYoutube />}
                  alt="youtube"
                  href="https://www.youtube.com/channel/UCEw_LBwuBBfxZIG9OaQj5_w"
                  variant="light"
                  height={12}
                  width={15}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-12 md:col-span-6 lg:col-span-2">
          <ColSectionFooter title="Produk & Layanan" menus={serviceMenu} />
        </div>
        <div className="col-span-12 md:col-span-6 lg:col-span-2">
          <ColSectionFooter title="Tentang Kami" menus={aboutMenu} />
        </div>
      </div>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-6 lg:col-span-3">
          <p className="font-semibold mb-1 text-white text-base">Kredit Bangun Rumah</p>
          <div className="flex items-center gap-x-2">
            <Link href={'https://www.bankmandiri.co.id/en/faq-kerjasama-bank-mandiri-dengan-sobat-bangun'} targetBlank>
              <Image src={LogoMandiri} alt="logo-mandiri" height={52} width={52} loading="lazy" />
            </Link>
            <Link href={'https://www.btn.co.id/id/Conventional/Product-Links/Produk-BTN/Kredit-Konsumer/Pinjaman-Bangunan/Kredit-Agunan-Rumah-BTN'} targetBlank>
              <Image src={LogoBTN} alt="logo-btn" height={52} width={52} loading="lazy" />
            </Link>
            <Link href={'https://www.bni.co.id/id-id/individu/pinjaman/bni-griya'} targetBlank>
              <Image src={LogoBNI} alt="logo-bni" height={52} width={52} loading="lazy" />
            </Link>
          </div>
        </div>
        <div className="col-span-12 md:col-span-6 lg:col-span-3">
          <p className="font-semibold mb-1 text-white text-base">Tunai Via Bank Transfer</p>
          <div className="flex items-center gap-x-2">
            <Image src={LogoMandiri} alt="logo-mandiri" height={52} width={52} loading="lazy" />
            <Image src={LogoBRI} alt="logo-bri" height={52} width={52} loading="lazy" />
            <Image src={LogoBNI} alt="logo-bni" height={52} width={52} loading="lazy" />
            <Image src={LogoPermataBank} alt="logo-permata-bank" height={52} width={52} loading="lazy" />
          </div>
        </div>
        <div className="col-span-12 md:col-span-6 lg:col-span-3">
          <p className="font-semibold mb-1 text-white text-base">Kartu Kredit</p>
          <div className="flex items-center gap-x-2">
            <Image src={LogoVisa} alt="logo-visa" height={52} width={52} loading="lazy" />
            <Image src={LogoMasterCard} alt="logo-master-card" height={52} width={52} loading="lazy" />
            <Image src={LogoJCB} alt="logo-jcb" height={52} width={52} loading="lazy" />
          </div>
        </div>
        <div className="col-span-12 md:col-span-6 lg:col-span-3">
          <p className="font-semibold mb-4 text-white text-base">Keamanan & Privasi</p>
          <div className="flex items-center gap-x-2">
            <a
              href="https://www.bsigroup.com/en-ID/validate-bsi-issued-certificates/client-directory-results/?q=PT%20Sinergi%20Informatika%20Semen%20Indonesia"
              target="_blank"
              rel="noopener noreferrer"
              className="group"
            >
              <div className="relative pb-2">
                <Image src={LogoMark} alt="mark-of-trust" width={72} height={72} loading="lazy" className="transition-all duration-300 group-hover:opacity-50" />
                <div className="absolute inset-0 bg-red-500 opacity-0 transition-opacity duration-300 group-hover:opacity-50"></div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="block md:hidden">
        <p className="mb-4">Sosial Media :</p>
        <div className="flex gap-x-6">
          <SocmedItem icon={<GrInstagram />} alt="instagram" href="https://www.instagram.com/sobatbangun/" variant="dark" height={15} width={15} />
          <SocmedItem icon={<GrFacebookOption />} alt="facebook" href="https://www.facebook.com/SobatBangun/" variant="light" height={15} width={10} />
          <SocmedItem icon={<GrYoutube />} alt="youtube" href="https://www.youtube.com/channel/UCEw_LBwuBBfxZIG9OaQj5_w" variant="light" height={12} width={15} />
        </div>
      </div>
      {/* Copyright */}
      <div className="flex flex-col md:flex-row justify-start md:justify-between md:items-center text-white pt-[51px]">
        <div className="flex justify-start md:justify-center items-center space-x-4">
          <Text className="text-white">Dipersembahkan oleh:</Text>
          <Image src={LogoSIG} alt="logo" height={40} width={70} loading="lazy" />
        </div>
        <div className="w-full h-[1px] bg-white/10 my-8 md:hidden" />
        <Text className="text-white tracking-wide">Copyright © 2023 PT. Sinergi Informatika Semen Indonesia. All rights reserved.</Text>
      </div>
    </footer>
  );
};

export default Footer;

const SocmedItem = ({ icon, variant, href, targetBlank, alt, ...props }: TSocmedItem) => {
  return (
    <Link
      href={href}
      className={`inline-flex items-center justify-center transition-all ease-in-out duration-300 text-[#2C373E] hover:text-white bg-white hover:bg-[#2C373E] w-[35px] h-[35px] md:w-[50px] md:h-[50px] rounded-full`}
      targetBlank={targetBlank}
      ariaLabel={alt}
    >
      {icon}
    </Link>
  );
};

const ColSectionFooter = ({ title, menus }: TColSectionFooter) => {
  return (
    <div className="flex flex-col basis-2/4 text-white">
      <p className="font-bold mb-6 text-white text-xl">{title}</p>
      <div className="flex flex-col gap-y-4 ">
        {menus?.map(({ href, children }, index) => (
          <Link href={href as string} className="transition-all ease-in-out duration-200 text-white hover:text-primary-base" key={href as string} ariaLabel={title}>
            {children}
          </Link>
        ))}
      </div>
    </div>
  );
};
