import clsx from 'clsx';
import { DetailedHTMLProps, HTMLAttributes, ReactElement } from 'react';

type TextProps = DetailedHTMLProps<HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement> & {
  children: React.ReactNode;
  as?: 'p' | 'span';
  color?: 'primary' | 'secondary' | 'black' | 'grey';
  className?: string;
  bold?: boolean;
};

export const Text = ({ as = 'p', children, color = 'black', className = '', bold, ...rest }: TextProps): ReactElement => {
  const Wrapper: keyof JSX.IntrinsicElements = as;

  const contentStyles = clsx('font-primary text-sm', className, {
    'text-black': color === 'black',
    'text-[#F5333F]': color === 'primary',
    'text-secondary-base': color === 'secondary',
    'text-grey-700': color === 'grey',
    'font-bold': bold,
    'font-normal': !bold,
  });

  return (
    <Wrapper className={contentStyles} {...rest}>
      {children}
    </Wrapper>
  );
};
