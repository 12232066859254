import { ReactElement, forwardRef, Ref } from 'react';
import NextLink, { LinkProps } from 'next/link';

type TLinkProps = LinkProps & {
  children: React.ReactNode;
  variant?: 'solid' | 'outline' | 'ghost' | 'basic';
  loading?: boolean;
  size?: 'sm' | 'md' | 'lg';
  fullWidth?: boolean;
  color?: 'primary' | 'secondary';
  className?: string;
  targetBlank?: boolean;
  ariaLabel?: string;
};

export const Link = forwardRef(
  (
    { variant = 'basic', children, className = '', loading, size = 'md', fullWidth = true, color = 'primary', targetBlank, ariaLabel = '', ...rest }: TLinkProps,
    ref: Ref<HTMLAnchorElement>
  ): ReactElement => {
    let contentStyles = '';

    if (variant === 'basic') {
      contentStyles = '';
    } else if (variant === 'solid') {
      contentStyles = 'bg-[#F5333F] ring-2 ring-primary-base text-white disabled:bg-[#F9858C] disabled:ring-0 disabled:cursor-not-allowed';
    } else if (variant === 'outline' && color === 'primary') {
      contentStyles = 'bg-white text-[#F5333F] border border-[#F5333F]';
    } else if (variant === 'outline' && color === 'secondary') {
      contentStyles = 'bg-white text-secondary-base border border-secondary-base';
    } else if (variant === 'ghost') {
      contentStyles = 'bg-white text-secondary-base';
    }

    let contentSize = '';

    if (size === 'lg') {
      contentSize = 'px-3 py-4';
    } else if (size === 'md') {
      contentSize = 'px-3 py-2';
    } else if (size === 'sm') {
      contentSize = 'px-2 py-1';
    }

    if (variant === 'basic') {
      return (
        <NextLink target={targetBlank ? '_blank' : '_self'} {...rest} className={className}>
          {children}
        </NextLink>
      );
    }

    return (
      <NextLink
        ref={ref}
        className={`rounded-md flex items-center justify-center font-semibold ${fullWidth && 'w-full'} ${contentSize} ${contentStyles} ${className}`}
        target={targetBlank ? '_blank' : '_self'}
        aria-label={ariaLabel}
        {...rest}
      >
        {loading ? <SpinAnimation /> : children}
      </NextLink>
    );
  }
);

const SpinAnimation = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="animate-spin w-4 h-4">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
      />
    </svg>
  );
};
