export const Route = {
  SIGNUP_INDEX: '/auth/register',
  // SIGNUP_PARTNER: 'https://partner-sobat-bangun-dev.dot.co.id/auth/register',
  SIGNUP_PARTNER: process.env.NEXT_PUBLIC_PARTNER_URL + '/auth/register',
  LOGIN_PARTNER: process.env.NEXT_PUBLIC_PARTNER_URL + '/auth/login',
  SIGNUP_FORM: '/auth/register/form',
  SIGNUP_OTP: '/auth/register/otp',
  SIGNUP_CREATE_PASSWORD: '/auth/register/create-password',
  FORGET_PASSWORD: '/auth/password/forgot-password',
  LOGIN: '/auth/login',

  PROFILE: '/profile',
  CHANGE_PASSWORD: '/profile/change-password',
  PKS: '/profile/pks',
  BANK_ACCOUNT: '/profile/bank-account',

  TERMS_AND_CONDITION: '/syarat-dan-ketentuan',
  PRIVACY_POLICY: '/kebijakan-privasi',

  DASHBOARD: '/dashboard',
  ABOUT_US: '#',
  PRODUCTS: '/produk',
  SERVICES: '/layanan',
  MATERIALS: '/material',
  NEWS: '/news',
  ARTICLES: '/artikel',
  FAQ: '/faq',
  APPOINTMENT: '/appointment',

  RENOVATION: '/layanan',
  CONSTRUCTION: '/layanan/bangun-rumah',
  DESIGN: '/layanan/desain',
  DESIGN_INTERIOR: '/layanan/desain-interior',
  TECHNOLOGY: '/layanan/teknologi-tambahan',
  POLICY: '/kebijakan-privasi',
  TERMS: '/syarat-dan-ketentuan',
  USERS: '/users',
};
